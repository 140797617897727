<template>
  <MainHeader>
    <div class="header-row">
      <div class="title-section">
        <div class="sidebar-btn" @click="$emit('openSidebar')"></div>
        <div class="title-container">
          <div class="title">Reports</div>
        </div>
      </div>
      <UserInfoHeaderSection :username="currentUser.full_name" />
    </div>
  </MainHeader>
  <div class="main-content-reports">
    <div class="title-ask">
      Are you ready to print your Simple Sales Pipeline?
    </div>
  </div>
  <div class="main-action">
    <div class="action-menu-month">
      <div class="action-menu-title">
        {{ 'Generate Report' }}
      </div>
      <div class="error-message" v-if="errorMessage">
        {{ errorMessage }}
      </div>
      <div class="month-wrapper">
        <div class="reports-month-field" @click="openMonthDropDown">
          {{ textMonth }}
          <div
            class="open-dropdown-btn"
            :class="{ 'close-dropdown-btn': isMonthDropdownOpened }"
          ></div>
        </div>
        <ActionMenu
          ref="actionsMenu"
          :width="'300px'"
          :top="'55px'"
          :height="'444px'"
          :isActionMenuShown="isMonthDropdownOpened"
          @closeActionMenu="closeMonthDropDown"
        >
          <ActionMenuItem
            v-for="month in pipelines.pipelinesList"
            :key="month.id"
            :text="setPipelineDate(month.timestamp)"
            @actionMenuItemClick="
              setNewMonth(setPipelineDate(month.timestamp), month.id)
            "
          />
          <div class="loading-list-container">
            <div class="spinner" v-if="isLoading"></div>
          </div>
        </ActionMenu>
      </div>
      <ActionButton
        v-if="!isLoading"
        :class="{ 'inactive-btn': !isSubscriptionActive }"
        :width="'300px'"
        :height="'45px'"
        :text="'Generate Report'"
        @click="handleGenerateReport"
      />
      <div class="action-btn loading-indicator-container" v-if="isLoading">
        <div class="spinner"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import MainHeader from '../../common/header/MainHeader.vue'
import UserInfoHeaderSection from '../../common/header/UserInfoHeaderSection.vue'
import ActionMenu from '../../common/actionMenu/ActionMenu.vue'
import ActionMenuItem from '../../common/actionMenu/ActionMenuItem.vue'
import ActionButton from '../../common/ActionButton.vue'
import Spinner from '../../common/Spinner.vue'

import { months } from '../../../utils/helpers'
import { UserSubscriptionStatus } from '../../../utils/constants'

export default {
  components: {
    Spinner,
    MainHeader,
    UserInfoHeaderSection,
    ActionMenu,
    ActionMenuItem,
    ActionButton,
  },
  emits: ['openSidebar'],
  data() {
    return {
      textMonth: '',
      isMonthDropdownOpened: false,
      pipelienMonthArray: months,
      currentPipelienId: 0,
      lastId: 0,
      isLoading: true,
      errorMessage: '',
      activeSubscriptionStatuses: [
        UserSubscriptionStatus.Granted,
        UserSubscriptionStatus.Active,
        UserSubscriptionStatus['Granted Until'],
        UserSubscriptionStatus.Canceled,
      ],
    }
  },
  mounted() {
    this.$nextTick(() => this.handlePipelinesScroll())
  },
  async created() {
    await this.getInitialData()
  },
  computed: {
    ...mapState(['currentUser', 'pipelines', 'activePipeline']),
    isSubscriptionActive() {
      return this.activeSubscriptionStatuses.includes(
        this.currentUser.subscription.status
      )
    },
  },
  methods: {
    ...mapActions([
      'getPipelinesList',
      'getMonthReportHtml',
      'getActivePipeline',
      'updatePipelinesList',
    ]),
    async updatedPipelinesList() {
      try {
        this.$refs.actionsMenu.$el.removeEventListener('scroll', this.listener)
        this.lastId =
          this.pipelines.pipelinesList[
            this.pipelines.pipelinesList.length - 1
          ].id
        this.isLoading = true

        await this.updatePipelinesList(this.lastId)

        if (
          this.pipelines.pipelinesCount > this.pipelines.pipelinesList.length
        ) {
          this.$refs.actionsMenu.$el.addEventListener('scroll', this.listener)
        }
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isLoading = false
      }
    },
    listener() {
      const scrolled =
        this.$refs.actionsMenu.$el.scrollTop +
        this.$refs.actionsMenu.$el.clientHeight
      const scrollHeight = this.$refs.actionsMenu.$el.scrollHeight - 20

      if (scrolled > scrollHeight) {
        this.updatedPipelinesList()
      }
    },
    handlePipelinesScroll() {
      if (!this.$refs.actionsMenu.$el) return

      this.$refs.actionsMenu.$el.addEventListener('scroll', this.listener)
    },
    handleGeneralError(error) {
      console.log('==== error while obtaining pipeline info: ', error)
      this.errorMessage = "Empty report can't be generated"
    },
    setPipelineDate(timestamp) {
      const date = new Date(timestamp * 1000)
      const pipelineDate =
        this.pipelienMonthArray[date.getUTCMonth()] +
        '  ' +
        date.getUTCFullYear()

      return pipelineDate
    },
    async getInitialData() {
      try {
        this.isLoading = true

        await this.getActivePipeline()
        await this.getPipelinesList(this.lastId)

        this.setActivePipeline()
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isLoading = false
      }
    },
    async handleGenerateReport() {
      if (!this.isSubscriptionActive) return

      this.isLoading = true
      this.errorMessage = ''

      try {
        await this.getMonthReportHtml(this.currentPipelienId)
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isLoading = false
      }
    },
    setActivePipeline() {
      const date = new Date(this.activePipeline.timestamp * 1000)
      const activePipelineDate =
        this.pipelienMonthArray[date.getUTCMonth()] +
        ' ' +
        date.getUTCFullYear()

      this.textMonth = activePipelineDate
      this.currentPipelienId = this.activePipeline.id
    },
    setNewMonth(month, pipelineId) {
      this.isMonthDropdownOpened = false
      this.currentPipelienId = pipelineId
      this.textMonth = month
    },
    openMonthDropDown() {
      this.isMonthDropdownOpened = true
    },
    closeMonthDropDown() {
      this.isMonthDropdownOpened = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styleVars.scss';

.inactive-btn {
  background-color: #9c9ea6 !important;
  cursor: not-allowed;
}

.header-row {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: $fontColorBlack;

  .sidebar-btn {
    width: 0;
    height: 0;
    position: absolute;
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../assets/icons/hamburger-icon.svg');
  }

  .title-section {
    display: flex;
    justify-content: center;
    align-items: center;

    .title-container {
      display: flex;
      flex-direction: column;

      .title {
        font-size: $fontSizeTitle;
      }
      .about-info {
        width: 16px;
        height: 16px;
        position: absolute;
        left: 410px;
        top: 45px;
        background-size: 16px;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        background-image: url('../../../assets/icons/about-info-icon.svg');
      }
    }
  }
}
.main-content-reports {
  width: 95%;
  height: 50px;
  border: 1px dashed #69c9a0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .title-ask {
    font-size: $fontSizeModalTitle;
  }
}
.main-action {
  width: 95%;
  .error-message {
    width: 100%;
    height: calc(100% - 104px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: $redWarningColor;
  }
  .action-menu-month {
    margin-top: 20px;
    background-color: white;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    height: 565px;
    width: 380px;
    border-radius: 4px;

    .action-menu-title {
      display: flex;
      align-items: center;
      width: 295px;
      height: 82px;
      font-size: $fontSizeTitle;
    }

    .action-btn {
      width: 300px;
      height: 45px;
      min-height: 45px;
      display: flex;
      justify-content: center;
      background-color: $appActionColor;
      color: $fontColorWhite;
      margin-top: 20px;
      cursor: pointer;

      &:hover {
        background-color: $appActionColorHovered;
      }
    }

    .loading-indicator-container {
      cursor: wait;

      &:hover {
        background-color: $appActionColor;
      }

      .spinner {
        margin-top: 10px;
        border: 5px solid $whiteColor;
        border-radius: 50%;
        border-top: 5px solid $appActionColor;
        width: 15px;
        height: 15px;
        animation: spin 2s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .month-wrapper {
      width: 300px;
      height: 68px;
      position: relative;

      .loading-list-container {
        width: 250px;
        height: 44px;
        cursor: wait;
        .spinner {
          margin-top: 5px;
          border: 5px solid $whiteColor;
          border-radius: 50%;
          border-top: 5px solid $appActionColor;
          width: 25px;
          height: 25px;
          display: flex;
          margin-left: auto;
          margin-right: auto;
          animation: spin 2s linear infinite;
        }
      }

      .reports-month-field {
        background-color: white;
        width: 300px;
        height: 44px;
        display: flex;
        align-items: center;
        margin-top: 8px;
        padding: 0 6px;
        position: relative;
        font-size: $fontSizeStandart;
        border: 1px solid $grayBorderColor;
        box-sizing: border-box;
        border-radius: 4px;
        cursor: pointer;

        .open-dropdown-btn {
          width: 20px;
          height: 20px;
          top: 12px;
          right: 19px;
          position: absolute;
          background-position: center;
          background-size: 20px;
          background-repeat: no-repeat;
          background-image: url('../../../assets/icons/chevron-down-black-icon.svg');
        }

        .close-dropdown-btn {
          background-image: url('../../../assets/icons/chevron-up-black-icon.svg');
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .header-row {
    align-items: center;

    .sidebar-btn {
      position: relative;
      width: 24px;
      height: 24px;
      margin-right: 15px;
    }

    .action-button {
      margin-top: 0;
    }
  }
}
@media (max-width: 420px) {
  .action-menu {
    width: 300px !important;
  }
  .header-row {
    position: relative;

    .title-section {
      margin-right: 10px;
      max-width: 250px;
      max-height: 200px;
    }

    .sidebar-btn {
      position: relative;
    }
  }
  .main-content-reports {
    .title-ask {
      font-size: $fontSizeStandart;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '../../../assets/styleVars.scss';

.error-message {
  width: calc(100% - 60px);
  height: 15%;
  max-height: 44px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $redWarningColor;
}
</style>
